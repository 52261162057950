import React, { ReactNode, useCallback, useEffect, useState } from 'react'
import {
    TextField,
    Autocomplete as MuiAutoComplete,
    AutocompleteRenderInputParams,
    AutocompleteInputChangeReason,
    CSSObject,
} from '@mui/material'

import { identity } from '@a10base/common/misc.js'
import { useThrottledAsyncFn } from '../hooks/throttled.js'
import { handleErrorAndNotify } from '../util/flash-notifications.js'

interface AutoCompleteProps<T> {
    options: T[]
    value: T | null
    loading?: boolean
    disabled?: boolean
    label?: string
    sx?: CSSObject
    onChange: (newValue: T | null) => void
    itemLabel: (item: T) => string
    renderItem: (item: T) => ReactNode
    itemId: (item: T | null | undefined) => number | string
}

export function AutoComplete<T>(props: AutoCompleteProps<T>) {
    const {
        options,
        value,
        loading,
        disabled,
        label,
        sx,
        onChange,
        itemLabel,
        renderItem,
        itemId,
    } = props

    const onChangeHandler = useCallback(
        (_event: unknown, newValue: T | null) => {
            onChange(newValue)
        },
        [onChange]
    )

    const renderInput = useCallback(
        (params: AutocompleteRenderInputParams) => {
            return (
                <form autoComplete="off">
                    <TextField {...params} fullWidth label={label} autoComplete="false" />
                </form>
            )
        },
        [label]
    )

    const renderOption = useCallback(
        (props: React.HTMLAttributes<HTMLLIElement>, item: T) => {
            return <li {...props}>{renderItem(item)}</li>
        },
        [renderItem]
    )

    const isEqual = useCallback(
        (option: T, val: T) => {
            return itemId(option) === itemId(val)
        },
        [itemId]
    )

    return (
        <MuiAutoComplete
            sx={{ minWidth: sx?.minWidth ?? '300px', ...sx }}
            fullWidth
            disabled={disabled}
            getOptionLabel={itemLabel}
            filterOptions={identity}
            options={options}
            autoComplete
            includeInputInList
            loading={loading}
            filterSelectedOptions
            value={value}
            noOptionsText="No options"
            onChange={onChangeHandler}
            renderInput={renderInput}
            renderOption={renderOption}
            isOptionEqualToValue={isEqual}
        />
    )
}

interface AsyncAutoCompleteProps<T> {
    value: T | null
    loading?: boolean
    disabled?: boolean
    label?: string
    onChange: (newValue: T | null) => void
    search: (searchTerm: string) => Promise<T[]>
    itemLabel: (item: T) => string
    renderItem: (item: T) => ReactNode
    itemId: (item: T | null | undefined) => number | string
}

export function AsyncAutoComplete<T>(props: AsyncAutoCompleteProps<T>) {
    const { value, loading, disabled, label, onChange, search, itemLabel, renderItem, itemId } =
        props
    const [items, setItems] = useState<T[]>(value ? [value] : [])

    const safeSearch = useCallback(
        async (searchTerm: string) => {
            try {
                const items = await search(searchTerm)
                setItems(items)
            } catch (error) {
                handleErrorAndNotify(error)
            }
        },
        [search]
    )
    const { setParams, throttling, requesting } = useThrottledAsyncFn(safeSearch, 500)

    useEffect(() => {
        setParams('')
    }, [setParams])

    const processing = loading || requesting || throttling

    const onChangeHandler = useCallback(
        (_event: unknown, newValue: T | null) => {
            onChange(newValue)
        },
        [onChange]
    )

    const onInputChangeHandler = useCallback(
        (_event: unknown, input: string, reason: AutocompleteInputChangeReason) => {
            if (reason === 'input') {
                setParams(input)
            }
        },
        [setParams]
    )

    const renderInput = useCallback(
        (params: AutocompleteRenderInputParams) => {
            return (
                <TextField
                    {...params}
                    fullWidth
                    label={label}
                    // InputProps={{
                    //     ...params.InputProps,
                    //     endAdornment: (
                    //         <InputAdornment position="end">
                    //             {processing ? <Spinner spinning /> : <SearchIcon />}
                    //         </InputAdornment>
                    //     ),
                    // }}
                />
            )
        },
        [label]
    )

    const renderOption = useCallback(
        (props: React.HTMLAttributes<HTMLLIElement>, item: T) => {
            return <li {...props}>{renderItem(item)}</li>
        },
        [renderItem]
    )

    const isEqual = useCallback(
        (option: T, val: T) => {
            return itemId(option) === itemId(val)
        },
        [itemId]
    )

    return (
        <MuiAutoComplete
            sx={{ minWidth: '300px' }}
            fullWidth
            disabled={disabled}
            getOptionLabel={itemLabel}
            filterOptions={identity}
            options={items}
            autoComplete
            includeInputInList
            loading={processing}
            filterSelectedOptions
            value={value}
            noOptionsText="No options"
            onChange={onChangeHandler}
            onInputChange={onInputChangeHandler}
            renderInput={renderInput}
            renderOption={renderOption}
            isOptionEqualToValue={isEqual}
        />
    )
}

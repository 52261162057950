import { scoringOptions } from './options.js'

export function formatInterestPercentRange(value: number) {
    if (value === 5) {
        return '5 - 10 %'
    }
    if (value === 10) {
        return '10 - 20 %'
    }
    if (value === 20) {
        return 'yli 20 %'
    }
    return `${value} - ${value + 5} %`
}
export function formatPaybackTime(value: number) {
    return `${value} ${value === 1 ? 'vuosi' : 'vuotta'}`
}
export function formatCreditScore(value: number) {
    return scoringOptions.creditScore.find(option => option.value === value)?.label ?? '?'
}
export function formatRevenue(value: number) {
    return scoringOptions.revenue.find(option => option.value === value)?.label ?? '?'
}
// export function formatCompanyAge(value: number) {
//     return scoringOptions.companyAge.find(option => option.value === value)?.label ?? '?'
// }
export function formatLoanAmount(value: number) {
    return scoringOptions.loanAmount.find(option => option.value === value)?.label ?? '?'
}
export function formatApproxInterestPercent(value?: number) {
    if (!value || value === Number.MAX_SAFE_INTEGER) {
        return ''
    }
    return `${Math.round(value)} %`
}
export function getScoreText(score?: number) {
    if (score === undefined) {
        return '?'
    }
    switch (score) {
        case 0:
            return 'No chances (0/4)'
        case 1:
            return 'Small chances (1/4)'
        case 2:
            return 'Reasonable chances (2/4)'
        case 3:
            return 'Good chances (3/4)'
        case 4:
            return 'Very good chances (4/4)'
        default:
            return '?'
    }
}

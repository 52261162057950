import React, { useCallback, useMemo, useState, JSX } from 'react'
import {
    Box,
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    LinearProgress,
    Stack,
    TextField,
    Typography,
} from '@mui/material'
import { CloudUpload as CloudUploadIcon } from '@mui/icons-material'

import type { PublicAsset } from '@a10base/common/types/index.js'
import {
    formatDateTime,
    handleErrorAndNotify,
    isEnter,
    rawFileUploadPromise,
    SearchParams,
    serverData,
    showNotification,
    trpcBase,
} from '@a10base/frontend/util/index.js'

import {
    AsyncTable,
    BaseTableColumn,
    CreateNewButton,
    PageTitleRow,
    RemoveButtonWithConfirmation,
    SaveCancelButton,
    useAsyncTable,
    useUrlTableState,
} from '@a10base/frontend/components/index.js'
import {
    formatFileSize,
    getPublicUrl,
    normalizeS3Key,
    parseFilename,
    truncate,
} from '@a10base/common/index.js'
import { useAsyncFn2Param } from '@a10base/frontend/hooks/async-function.js'
import { useSelector } from 'react-redux'

export const PublicAssetsPage: React.FC = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const searchParams = useSelector<any>(state => state.url.searchParams) as SearchParams
    const tableState = useUrlTableState(searchParams, 'assets', 50)
    const loadAssets = useCallback((offset: number, limit: number, searchText?: string) => {
        return trpcBase.admin.item.getItems.query({
            table: 'public_asset',
            search: searchText,
            offset,
            limit,
            orderBy: 'created',
            orderDir: 'DESC',
        }) as unknown as Promise<PublicAsset[]>
    }, [])
    const tableProps = useAsyncTable(loadAssets, tableState)
    const columns = useMemo<BaseTableColumn<PublicAsset>[]>(
        () => [
            {
                id: 'type',
                header: '',
                render: v =>
                    v.widths?.length ? (
                        <img
                            src={getAssetUrl(`${v.name}-${v.widths[0]}`, v.ext)}
                            alt="thumbnail"
                            style={{ maxHeight: '30px' }}
                        />
                    ) : (
                        <Chip label={v.ext} size="small" />
                    ),
            },
            {
                id: 'url',
                header: 'Url',
                render: v => assetLink(truncate(v.name, 64), v.name, v.ext),
            },
            { id: 'size', header: 'Size', render: v => formatFileSize(v.size_b) },
            {
                id: 'versions',
                header: 'Versions',
                render: v =>
                    v.widths?.length ? (
                        <Stack direction="row" spacing={1}>
                            {v.widths?.map(width =>
                                assetLink(`${width}`, `${v.name}-${width}`, v.ext)
                            )}
                        </Stack>
                    ) : null,
            },
            { id: 'created', header: 'Created', render: v => formatDateTime(v.created) },
            {
                id: 'action',
                header: '',
                render: v => <DeleteButton asset={v} onDeleted={tableProps.reloadRows} />,
            },
        ],
        [tableProps.reloadRows]
    )
    return (
        <Stack direction="column" spacing={1} width="100%">
            <PageTitleRow title="Public assets">
                <CreateNewBtn onCreated={tableProps.reloadRows} />
            </PageTitleRow>
            <AsyncTable size="small" columns={columns} {...tableProps} {...tableState} searchable />
        </Stack>
    )
}

function assetLink(label: string, name: string, ext: string): JSX.Element {
    return (
        <a key={name} href={getAssetUrl(name, ext)} target="_blank" rel="noreferrer">
            {label}
        </a>
    )
}

function getAssetUrl(name: string, ext: string): string {
    return getPublicUrl({
        project: serverData.config.A10_PROJECT,
        env: serverData.config.A10_ENV,
        key: `assets/${name}${ext}`,
        publicBucketCdn: serverData.config.PUBLIC_BUCKET_CDN,
    })
}

interface DeleteButtonProps {
    asset: PublicAsset
    onDeleted: () => void
}
function DeleteButton({ asset, onDeleted }: DeleteButtonProps) {
    const remove = useCallback(async () => {
        await trpcBase.admin.publicAsset.deleteAsset.mutate({ id: asset.id })
        onDeleted()
    }, [asset.id, onDeleted])

    return (
        <RemoveButtonWithConfirmation
            removeFn={remove}
            onlyIcon
            modalBody={`Delete asset "${asset.name}"?`}
        />
    )
}

interface CreateNewButtonProps {
    onCreated: () => void
}
const CreateNewBtn: React.FC<CreateNewButtonProps> = ({ onCreated }) => {
    const [showModal, setShowModal] = useState<boolean>(false)
    return (
        <Box>
            <CreateNewButton onClick={() => setShowModal(true)}>Upload new</CreateNewButton>
            {showModal && (
                <CreateNewModal
                    onClose={(created: boolean) => {
                        if (created) {
                            onCreated()
                        }
                        setShowModal(false)
                    }}
                />
            )}
        </Box>
    )
}

interface CreateNewModalProps {
    onClose: (created: boolean) => void
}
const CreateNewModal: React.FC<CreateNewModalProps> = ({ onClose }) => {
    const [name, setName] = useState<string>('')
    const [ext, setExt] = useState<string>('')
    const [progress, setProgress] = useState<number | undefined>(undefined)
    const [file, setFile] = useState<File | undefined>(undefined)

    const onChangeFileInput = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files?.length) {
            const file = e.target.files[0]
            setFile(file)
            const [name, ext] = parseFilename(normalizeS3Key(file.name))
            setName(name)
            setExt(ext)
        }
    }, [])

    const upload = useCallback(
        async (name: string, ext: string) => {
            if (file && name.length >= 4) {
                try {
                    setProgress(0)
                    await rawFileUploadPromise(
                        file,
                        `/api/admin/public-assets/upload?name=${encodeURIComponent(name + ext)}`,
                        setProgress
                    )
                    showNotification({ messageType: 'success', message: 'Asset uploaded' })
                    onClose(true)
                } catch (e) {
                    handleErrorAndNotify(e)
                } finally {
                    setProgress(undefined)
                }
            }
        },
        [file, onClose]
    )

    const uploadAction = useAsyncFn2Param(upload, name, ext)

    return (
        <Dialog open={true} onClose={() => onClose(false)} maxWidth="sm" fullWidth>
            <DialogTitle>Upload asset</DialogTitle>
            <DialogContent>
                <Stack direction="column" spacing={4} my={4}>
                    <Button
                        component="label"
                        role={undefined}
                        variant="contained"
                        tabIndex={-1}
                        startIcon={<CloudUploadIcon />}
                    >
                        Select file
                        <input
                            type="file"
                            onChange={onChangeFileInput}
                            style={{
                                clip: 'rect(0 0 0 0)',
                                clipPath: 'inset(50%)',
                                height: 1,
                                overflow: 'hidden',
                                position: 'absolute',
                                bottom: 0,
                                left: 0,
                                whiteSpace: 'nowrap',
                                width: 1,
                            }}
                        />
                    </Button>

                    {file && (
                        <Stack direction="row" spacing={0} alignItems="center">
                            <TextField
                                label="Filename"
                                autoComplete="off"
                                value={name}
                                onChange={e => setName(normalizeS3Key(e.target.value))}
                                onKeyUp={e => isEnter(e) && uploadAction.callFn()}
                            />
                            <Typography variant="body1">{ext}</Typography>
                        </Stack>
                    )}

                    {progress !== undefined && (
                        <LinearProgress variant="determinate" value={progress} />
                    )}
                </Stack>
            </DialogContent>
            <DialogActions>
                <SaveCancelButton
                    disabled={file === undefined || name.length < 4}
                    {...uploadAction}
                    onCancel={() => onClose(false)}
                    text="Upload"
                />
            </DialogActions>
        </Dialog>
    )
}
